// Generated by Framer (a7c2675)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/d2C9kOxg9";

const cycleOrder = ["giY011sUm"];

const serializationHash = "framer-zRlwL"

const variantClassNames = {giY011sUm: "framer-v-1y0mfr2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, In0VgETmc: link ?? props.In0VgETmc, Xs6jnvoa2: title ?? props.Xs6jnvoa2 ?? "Category"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Xs6jnvoa2, In0VgETmc, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "giY011sUm", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y0mfr2", className, classNames)} data-framer-name={"Category"} initial={variant} layoutDependency={layoutDependency} layoutId={"giY011sUm"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Um9ib3RvIE1vbm8tNTAw", "--framer-font-family": "\"Roboto Mono\", monospace", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "1.4em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-6014b03e-96b6-44fc-a8fe-1c6402d9a85e, rgb(181, 189, 197)))", "--framer-text-transform": "uppercase"}}><Link href={In0VgETmc} openInNewTab={false} smoothScroll={false}><motion.a className={"framer-styles-preset-4pa8r7"} data-styles-preset={"d2C9kOxg9"}>Category</motion.a></Link></motion.p></React.Fragment>} className={"framer-1r0tvnk"} fonts={["GF;Roboto Mono-500"]} layoutDependency={layoutDependency} layoutId={"Vo_HgLtqe"} style={{"--extracted-r6o4lv": "var(--token-6014b03e-96b6-44fc-a8fe-1c6402d9a85e, rgb(181, 189, 197))"}} text={Xs6jnvoa2} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-zRlwL[data-border=\"true\"]::after, .framer-zRlwL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zRlwL.framer-1aytr1d, .framer-zRlwL .framer-1aytr1d { display: block; }", ".framer-zRlwL.framer-1y0mfr2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 8px 8px 8px 8px; position: relative; width: min-content; }", ".framer-zRlwL .framer-1r0tvnk { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zRlwL.framer-1y0mfr2 { gap: 0px; } .framer-zRlwL.framer-1y0mfr2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-zRlwL.framer-1y0mfr2 > :first-child { margin-left: 0px; } .framer-zRlwL.framer-1y0mfr2 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 83
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Xs6jnvoa2":"title","In0VgETmc":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerp3Xzx45Ro: React.ComponentType<Props> = withCSS(Component, css, "framer-zRlwL") as typeof Component;
export default Framerp3Xzx45Ro;

Framerp3Xzx45Ro.displayName = "Components / Categories";

Framerp3Xzx45Ro.defaultProps = {height: 35, width: 83};

addPropertyControls(Framerp3Xzx45Ro, {Xs6jnvoa2: {defaultValue: "Category", displayTextArea: false, title: "Title", type: ControlType.String}, In0VgETmc: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerp3Xzx45Ro, [{family: "Roboto Mono", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPRu-5Ip2sSQ.woff2", weight: "500"}, ...sharedStyle.fonts])